import React from 'react'
import { cx, css } from 'linaria'

import { ReactComponent as SvgLogo } from '@/images/vim-motion/fasten-the-process.svg'

export const FastenTheProcess = () => {
  return (
    <section className="section">
      <div
        className={cx(
          'text-white mx-auto',
          css`
            max-width: 72.5rem;
            background: linear-gradient(242.21deg, #3888cc -2.31%, #2551cc 73.66%);
            box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.3), inset 0px -1px 1px rgba(0, 0, 0, 0.15),
              inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.25);
            border-radius: 8px;
          `
        )}
      >
        <div className={cx('container px-4')}>
          <div className="flex md:flex-row justify-between flex-wrap items-center -mx-4 flex-col-reverse">
            <div
              className={cx(
                'mb-5 md:mb-0 md:w-1/2 px-4 w-full md:w-1/2',
                css`
                  max-width: 400px;
                `
              )}
            >
              <h3 className="mb-4 text-section-title">Fasten the Process</h3>
              <div className="text-lg">{`It's always better to spend time on the actual work than take a toll on the wrist. Instead of alternatively shifting from mouse to keyboard and vice versa, let's just keep those fingers at one place only.`}</div>
            </div>
            <div className="w-full md:w-1/2 px-4 items-center justify-center">
              <SvgLogo width="auto" height="auto" className="mx-auto max-w-full" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
