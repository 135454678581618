import React from 'react'
import { graphql } from 'gatsby'
import { cx, css } from 'linaria'
import Img from 'gatsby-image'
import TweetEmbed from 'react-tweet-embed'

import { VimMotionApp as AppDetail } from '@components/contants'
import { Base } from '@ui/base'
import { Footer } from '@ui/footer'
import { Header } from '@ui/header'
import { CheckOn } from '@ui/check-on'
import { DownloadNow } from '@ui/download-now'

import Seo from '@components/seo-detail-page'
import { Jumbotron } from '@components/ui/jumbotron'
import bg from '@/images/vim-motion/banner.svg'
import { Intro } from '@components/ui/intro'
import demoVideo from '@/assets/video/demo-vim-motion.gif'
import DemoOnMac from '@ui/demo-on-mac'
import Section from '@components/ui/section'
import { FastenTheProcess } from '@components/vim-motion/fasten-the-process'
import { MoreProductivity } from '@components/vim-motion/more-productivity'
import { ReactComponent as KeyBoardSvg } from '@/images/vim-motion/keyboard.svg'
import RedditEmbed from '@components/vim-motion/reddit-embed'
import boxPng from '@/images/vim-motion/box.png'

const VimMotionDetailsPage = ({ data, path }) => {
  const images = data.allScreenshots.edges.map(({ node }) => node.childImageSharp.fixed.src)
  const hintModeSources = [data.hintMode?.childImageSharp.fluid]

  return (
    <>
      <Seo {...AppDetail} images={images} />

      <Base>
        <Header className="top-0 sticky w-full z-50" />
        <Jumbotron
          {...AppDetail}
          bgClass={css`
            background-image: url(${bg}),
              linear-gradient(0.2deg, rgba(42, 91, 243, 0.8) 0.43%, rgba(67, 186, 245, 0.8) 133.35%);
            background-size: cover;
            box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.3), inset 0px -1px 1px rgba(0, 0, 0, 0.15),
              inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.25);
            background-size: cover;
            background-color: #262626;
          `}
        />
        <div
          className={css`
            background-color: #0c142f;
            color: white;
          `}
        >
          <Intro
            title="There are many catches to abandon the mouse"
            details={[
              `To move quickly between lines. Switching from tabs to tabs. Navigate the right element in a second.`,
              `To limit the ratio of using mouses, which sometimes can be bothersome and time-consuming, Vim Motion is what we create to serve the need for better productivity.`,
            ]}
            className={css`
              @apply text-white;
            `}
          />
          <section
            className={cx(
              'z-10 relative pb-15 pt-20',
              css`
                margin-top: -20%;
              `
            )}
          >
            <div className="container px-4">
              <DemoOnMac>
                <img src={demoVideo} className="w-full" alt="Vim Motion tutorial video" />
              </DemoOnMac>
            </div>
          </section>
          <section className="section">
            <div className="container px-4">
              <h2 className="text-section-title text-center mb-6">From Mouse to Movement</h2>
              <div className="text-section-content">
                Inspired by Vim editor, Vim Motion performs through a set of keyboard and shortcut that transform your
                keyboard movement in two kinds of mode.
              </div>
            </div>
          </section>
          <Section
            title="Hint mode"
            description="Once this mode is turned on, a set of labels will appear that symbolize the control in the current windows. Depends on which control users prefer, they can type in the corresponding letter."
            MediaComponent={<Img className="w-full" fluid={hintModeSources} alt="Hint Mode" />}
          />
          <Section
            title="Scroll mode"
            description={`This helps users to scroll in a list-view window, by using the letters H - J - K - L that represents ← ↓ ↑ →.`}
            MediaComponent={<KeyBoardSvg className="w-full" />}
            isReverse={true}
          />
          <FastenTheProcess />
          <MoreProductivity />
          <section className="section px-4">
            <div className="mb-10 md:mb-20">
              <TweetEmbed id="1268881606586118144" options={{ theme: 'dark', align: 'center' }} />
            </div>
            <div className="mb-10 md:mb-20">
              <TweetEmbed id="1266726487488593920" options={{ theme: 'dark', align: 'center' }} />
            </div>
            <div
              className={css`
                @apply w-full mx-auto bg-white text-white rounded overflow-hidden;
                max-width: 550px;
              `}
            >
              <RedditEmbed />
            </div>
          </section>
          <CheckOn {...AppDetail} theme="dark" />
          <DownloadNow
            {...AppDetail}
            boxImage={boxPng}
            theme="dark"
            className={css`
              background-color: #121b3f;
            `}
          />
        </div>
        <Footer path={path} />
      </Base>
    </>
  )
}

export const query = graphql`
  query {
    allScreenshots: allFile(filter: { absolutePath: { regex: "/vim-motion/screenshots/" } }) {
      edges {
        node {
          childImageSharp {
            fixed(jpegQuality: 100, fit: CONTAIN, width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
    hintMode: file(relativePath: { eq: "vim-motion/hint-mode.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 460) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default VimMotionDetailsPage
