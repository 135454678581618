import React from 'react'
import { cx, css } from 'linaria'

export interface IntroProps {
  title: string
  details?: string[]
  children?: React.ReactNode
  className?: string
}
export const Intro = ({ title, details, children, className }: IntroProps) => {
  return (
    <section
      className={cx(
        'flex flex-col items-center text-secondary-black relative justify-center px-5',
        css`
          padding-top: 40px;
          padding-bottom: 15%;

          @screen md {
            padding-top: 120px;
          }
        `,
        className
      )}
    >
      <h2 className="text-section-title text-center mb-6">{title}</h2>
      <div
        className={cx(
          'w-full flex flex-col md:mb-20 mb-10 text-section-content text-center',
          css`
            @screen md {
              width: 80%;
              font-size: 21px;
            }
            @media (min-width: 1024px) {
              width: 760px;
            }
          `
        )}
      >
        {children}
        {!children &&
          details &&
          details.map((content, idx) => (
            <p className="mb-4" key={`detail-${idx}`}>
              {content}
            </p>
          ))}
      </div>
    </section>
  )
}
