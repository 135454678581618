import React from 'react'
import { cx } from 'linaria'

export interface SectionProps {
  className?: string
  title?: string
  leftColClassName?: string
  description?: string
  isReverse?: boolean
  MediaComponent?: React.ReactNode
  RightColumn?: React.ReactNode
}

const Section = ({
  className,
  title,
  leftColClassName,
  description,
  isReverse = false,
  MediaComponent,
  RightColumn,
}: SectionProps) => {
  return (
    <section className={cx('section', className)}>
      <div className="container px-4">
        <div className={cx('flex justify-between flex-wrap items-center -mx-4', isReverse && 'flex-row-reverse')}>
          <div className={cx('md:w-1/2 max-w-98 mb-5 md:mb-0 px-4', leftColClassName)}>
            <h2 className="text-section-title mb-4">{title}</h2>
            <p className="text-lg">{description}</p>
          </div>
          {RightColumn && RightColumn}
          {!RightColumn && <div className="w-full md:w-1/2 max-w-123 px-4">{MediaComponent}</div>}
        </div>
      </div>
    </section>
  )
}

export default Section
