import React, { useEffect } from 'react'

interface RedditEmbedProps {
  id: string
  url: string
}

const REDIT_CLASS = 'embedly-card'

const RedditEmbed = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.setAttribute('src', `//embed.redditmedia.com/widgets/platform.js`)
    script.setAttribute('charset', `UTF-8`)
    script.setAttribute('async', `true`)
    document.getElementsByClassName(REDIT_CLASS)[0].appendChild(script)
  }, [])
  return (
    <a
      className={REDIT_CLASS}
      href="https://www.reddit.com/r/vim/comments/grflcv/vimmotion_vimeasymotion_for_macos/frz6kz5"
    >
      Card
    </a>
  )
}

export default RedditEmbed
