import React from 'react'
import { cx, css } from 'linaria'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { ReactComponent as CheckIconSvg } from '@components/svg/check-icon.svg'

const list = ['Vim users who want to step up their experience', 'People who wish to advance their working pace']

export const MoreProductivity = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopLogo: file(relativePath: { eq: "vim-motion/setting.png" }) {
        childImageSharp {
          fluid(maxWidth: 540) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  const sources = [data?.desktopLogo?.childImageSharp.fluid]
  return (
    <section className={cx('section')}>
      <div className="container px-4">
        <div className={cx('flex justify-between flex-wrap items-center -mx-4 flex-row-reverse')}>
          <div className={cx('md:w-1/2 mb-5 md:mb-0 px-4')}>
            <h2 className="text-section-title mb-4">Less effort, More productivity</h2>
            <div className="text-lg">
              <p className="mb-4">What makes the right user</p>
              <ul className="list-reset">
                {list.map((item, idx) => (
                  <li key={item} className={cx('flex', idx !== 0 && 'mt-4')}>
                    <CheckIconSvg width="18" height="18" viewBox="0 0 24 24" className={cx('flex-none mt-1')} />
                    <span
                      className={cx(
                        'block text-lg',
                        css`
                          margin-left: 17px;
                        `
                      )}
                    >
                      {item}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div
            className={cx(
              'md:w-1/2 px-4',
              css`
                max-width: 35.75rem;
              `
            )}
          >
            <Img fluid={sources} alt="Less effort, More productivity" className="w-full" />
          </div>
        </div>
      </div>
    </section>
  )
}
